/// Libraries
import React, { useState, useRef, useEffect } from 'react';
import { GooglePlacesAutocomplete } from 'react-native-google-places-autocomplete';

// Components
import { P, H4 } from './text';

// Styles
import FncColors from '../styles/colors';
let Colors = [];

export default function GooglePlacesInput(props) {
  Colors = FncColors();
  const placeholder = props.placeholder || 'Zoeken';
  const onPress = props.onPress || function (data, details = null) { console.log(data, details) };
  const key = 'AIzaSyBgw7wN90k4szKlAL2A0XbO_ARvIbyesjg'; //<- account: netwerkbureagroeier@gmail.com (klimaatwijs op vakantie)
  const query = { key: key, language: 'nl' };
  const url = "https://proxy.klimaatwijsopvakantie.info/?url=" + encodeURI("https://maps.googleapis.com/maps/api");
  const requestUrl = { useOnPlatform: 'all', url: url };
  const placeHolderTextColor = props.placeHolderTextColor || Colors[9];
  const setText = props.setText || '';
  const enable = props.enable;
  const mobile = props.mobile;
  const tablet = props.tablet;
  const stapId = props.stap;
  const borderColor = props.borderColor;
  const maxWidth = props.maxWidth;
  const tussenvraag = props.tussenvraag || false;
  const desktop = props.desktop || false;
  //const onChangeText = props.onChangeText || null;
  //console.log(mobile);

  const ref = useRef();


  useEffect(() => {
    ref.current?.setAddressText(setText);
  }, [setText]);


    return (
      <GooglePlacesAutocomplete
        ref={ref}
        placeholder={enable ? placeholder : 'N.v.t'}
        onPress={onPress}
        query={query}
        requestUrl={requestUrl}
        debounce={500}
        minLength={2} //minimum length of text to search
        styles={{
          container: [borderColor, {
            marginTop: (tussenvraag ? 10 : null),
            maxWidth: maxWidth,
            height: '100%',
            borderBottomWidth: mobile ? 0 : stapId === 1145 && enable ? 1 : 0,
            borderRadius: mobile ? 0 : stapId === 1145 ? 5 : 0,
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            left: (tussenvraag ? 5 :0),
          }],
          listView: {
            position: (mobile || tussenvraag ? 'relative' : 'absolute'),
            top: (tussenvraag ? 0 : mobile ? 0 : 30),
            left: (tussenvraag ? 0 : mobile ? -20 : tablet ? -35 : -60),
            width: (tablet ? '120%' : tussenvraag ? '100%' : '150%'),
            maxWidth: maxWidth,
            zIndex: 100,
            fontFamily: 'Regular',
          },
          textInput: {
            height: (tussenvraag ? 40 : 23),
            fontSize: (tussenvraag ? 16 : tablet ? 12 : mobile ? 16 : 15),
            marginLeft: (tussenvraag ? 0 : -8),
            width: (tussenvraag ? '100%' : '65%'),
            maxWidth: maxWidth,
            paddingLeft: mobile ? 10 : 15,
            color: Colors[9],
            backgroundColor: 'rgba(100, 100, 100, 0.0)',
            zIndex: 100,
            paddingVertical: (tussenvraag ? 11 : 4),
            paddingHorizontal: (tussenvraag ? 17 : 10),
            borderWidth: (tussenvraag ? 1 : 0),
            borderColor: Colors[9],
            fontFamily: 'Regular',
            borderRadius: (tussenvraag ? global.settings.border_radius : 0),
            marginBottom: (tussenvraag || mobile || tablet ? 5 : 0),
          },
        }}
        textInputProps={{
          placeholderTextColor: (enable ? placeHolderTextColor : '#cfcfcf'),
          editable: enable,
          /*onChangeText: (text) => { console.log(text) }*/
        }}
      />
    );
};