/// Libraries
import React, { useState, useEffect, useMemo, useContext } from 'react';
//import { Helmet } from 'react-helmet';

// Styles

// Functions
import { textReplace, is_mobile, is_tablet, checkOutdatedCache, get_module_naam, get_property } from '../functions/common';

// Components
import Loader from '../components/loader';
import OuterContainer from '../components/outerContainer';
import MainContainer from '../components/mainContainer';
import { P } from '../components/text';


// Context
import { GlobalDispatcher, GlobalContext } from '../context/globalState';
import { SET_MODULE, SET_DATA, SET_DEBUG, SET_PARAM } from '../context/actions/commonActions';

//Variables

// data ophalen en mainContainer openen met data
export default function Main(props) {
  //Context
  const commonDispatch = useContext(GlobalDispatcher).commonDispatch;
  const commonContext = useContext(GlobalContext).commonState;
  const aom_data = commonContext.data;
  const param = commonContext.param;

  let linkParam = '';
  let debug = commonContext.debug;
  // console.log('debug', debug);

  //States
  const [loading, setLoading] = useState(true);
  // const [preLoading, setPreLoading] = useState(true);
  const [error, setError] = useState('');

  const mobile = is_mobile();
  const tablet = is_tablet();

  useEffect(() => {
    checkOutdatedCache();

    const tmp = new URL(window.location.href);
    const d = tmp.searchParams.get('debug');
    if ( d != "" && d != null ) {
      debug = d;
      commonDispatch({ type: SET_DEBUG, value: d });
    }
  }, []);

  // het laden van de specifieke module CSS
  if (!loading) {
    const moduleCss = get_property(aom_data, 'module.mod_css');
    const moduleId = get_property(aom_data, 'module.mod_id');

    // heeft deze module specifieke CSS?
    if (moduleCss) {
      // we gebruiken in het ID van de specfieke CSS het ID van de module
      var element = document.getElementById("moduleCss" + moduleId);
      if (typeof (element) != 'undefined' && element != null) {
        // De CSS is al geladen, dus niets doen
        //console.log( 'CSS was al geladen!', moduleId);
      }
      else {
        // de CSS is nog niet geladen,
        // eerst eventueel eerder geleden css (van andere mnodules) verwijderen
        var els = document.querySelectorAll('[id^=moduleCss]');
        for( var i = 0; i < els.length; i++ ){
          var linkNode = els[i];
          linkNode.parentNode.removeChild(linkNode);
        }

        // en nu de nieuwe CSS toevoegen
        //console.log( 'CSS laden!');
        const link = document.createElement('link');
        link.id = "moduleCss" + moduleId;
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = 'data:text/css,' + escape(moduleCss);
        link.media = 'all';
        document.head.appendChild(link);

      }
    }
  }

  useEffect(() => {
    if (props.route.params) {
      const paramVar = props.route.params.param;
      //console.log(paramVar);
      if (paramVar) {
        linkParam = paramVar
      }
    }
  }, []);

  //Voer api uit
  useEffect(() => {
    if (loading !== false) {
      let module_naam = get_module_naam(props);
      commonDispatch({ type: SET_MODULE, value: module_naam });
      let url = null;

      if(linkParam){
        commonDispatch({ type: SET_PARAM, value: JSON.parse(linkParam) });
        url = textReplace('[api_url]/module/' + module_naam + '?code=' + global.settings.api_key + '&param=' + encodeURIComponent(linkParam), [global.settings.api_url]);
      }
      else{
        // als naar het startscherm wordt genavigeerd
        // en de instelling staat op startscherm overslaan
        // dan param aanpassen
        if ( param.goto === 0 && global.settings.skip_startscherm == 1 ){
          const newParam = { goto: "first", moduleNaam: module_naam, chain: [] };
          commonDispatch( { type: SET_PARAM, value: newParam } );
          setLoading(null);
          return;
        }
        else {
          url = textReplace('[api_url]/module/' + module_naam + '?code=' + global.settings.api_key + '&param=' + encodeURIComponent(JSON.stringify(param)), [global.settings.api_url]);
        }
      }

      //console.log(url);

      if (debug) url += "&debug=" + debug;

      if (debug) console.log("api call: ", url);

      fetch(url)
        .then(response => response.json())
        .then(json => {
          if(debug) console.log(json);
          //Controleren op error
          if (json.success == false) {
            setError(json.error);
            console.log('error: ' + json.error);
            setLoading(false);
          }
          else {
            const disclaimer_titel = get_property(global, 'settings.disclaimer_titel');
            const disclaimer_inhoud = get_property(global, 'settings.disclaimer_inhoud');

            if (disclaimer_titel) json.data.module.mod_disclaimer_titel = disclaimer_titel;
            if (disclaimer_inhoud) json.data.module.mod_disclaimer_tekst = disclaimer_inhoud;

            commonDispatch({ type: SET_DATA, value: json.data });
            setLoading(false);
            setError('');
          }
        }
        )
        .catch((err) => {
          setError("Error in api.");
          console.log('error:' + err);
          setLoading(false);
        })
    }

    return function cleanup() {
    }
  }, [loading]);

  // Bepaal de module naam en stel de canonical URL in
  /*const moduleNaam = get_module_naam(props);
  const domein = global.settings.app_url;
  const canonicalHref =
    moduleNaam != ""
      ? domein + "/" + moduleNaam
      : domein + "/";*/

  return useMemo(() => {
    if (loading !== false) {
      return <Loader />;
    }
    else {
      return (
        <>
          {error ? (
            <P> {error} </P>
          ) : (
            <OuterContainer>
              <MainContainer
                navigation={props.navigation}
                mobile={mobile}
                tablet={tablet}
              />
            </OuterContainer>
          )}
        </>
      );
    }
  }, [loading])
};
